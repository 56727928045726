
import { FlipWords } from "../themes/flip-words";

export function FlipWordsHeader() {
  const words = ["fastighet?", "brf?", "lägenhet?", "villa?", "samfällighet?"];

  return (

    <h1 className="text-stone-800 font-bold text-[2.7rem] md:text-6xl py-1 font-display text-pretty">
      Dags att se över er
      <FlipWords words={words} /> <br />
    </h1>

  );
}
